import * as appActions from "../actions/app_actions";
import * as firebaseActions from "../actions/firebase_actions";
import * as paymentActions from "../actions/payment_actions";
import * as mystuffActions from "../actions/mystuff_actions";
import { push as nativePush } from 'connected-react-router';
import imageCompression from 'browser-image-compression';
import { isMobileWithoutTablet, isJson, poster } from './text';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/compat/messaging";

// import { cfaSignInApple, cfaSignInGoogle } from "capacitor-firebase-auth"
// import { user } from "firebase-functions/lib/providers/auth";
import klaviyoClient from './KlaviyoClient';

class FirebaseClient {
    constructor(store) {
        this._store = store;
    }

    setStore(store) {
        this._store = store;
    }

    initFirebase() {
        let fireBase = new Promise((resolve, reject) => {
            try {
                const config = {
                    apiKey: process.env.apiKey,
                    authDomain: process.env.authDomain,
                    databaseURL: process.env.databaseURL,
                    projectId: process.env.projectId,
                    storageBucket: process.env.storageBucket,
                    messagingSenderId: process.env.messagingSenderId,
                    appId: process.env.appId,
                    measurementId: process.env.measurementId
                };
                firebase.initializeApp(config);
                firebase.analytics();
                if (firebase.app().name) {
                    console.log('Firebase initialized');
                    this._store.dispatch(firebaseActions.initFirebase(true));
                    resolve(true);
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Firebase Promise Error: ' + reason);
            return Promise.resolve(null);
        });

        return fireBase;

    }

    isUserLogged() {
        let valid = new Promise((resolve, reject) => {
            const user = firebase.auth().currentUser;

            if (user && user.getIdToken) {
                user.getIdToken(true).then((token) => {
                    if (token) {
                        resolve(true)
                    } else {
                        resolve(false);
                        this.logOut();
                    }
                }).catch((error) => {
                    reject(error);
                    this.logOut();
                    console.log('getIdToken error', error);
                });
            } else {
                resolve(false);
                this.logOut();
            }
        });

        return valid;
    }

    init() {
        let appInit = new Promise((resolve, reject) => {
            try {
                if (firebase.app().name) {
                    firebase.auth().onAuthStateChanged((user) => {
                        if (user && user.uid) {
                            let { uid, displayName, email, photoURL, emailVerified } = user;
                            // console.log('User:', { uid, displayName, email, photoURL });
                            this.getUserData({ uid, displayName, email, photoURL, emailVerified }, true).then((user) => {
                                // console.log('NFB User: ', user);
                                this.getMyChannels();
                                this.listenToLiveUpdates();
                                this.getUserTicket(uid).then((tickets) => {
                                    this._store.dispatch(mystuffActions.setMyTickets(tickets));
                                    this.getMyStuffData(uid);
                                    this._store.dispatch(appActions.showLogIn(false));
                                    resolve(true);
                                }).catch(e => {
                                    console.error('Error getting user data', e.message);
                                    resolve(true);
                                });
                                this.readFromLiveTeasers();
                            });
                            klaviyoClient.load().then((ret) => {
                                console.log('About to call identify', { uid, displayName, email, photoURL })
                                klaviyoClient.identify({ uid, displayName, email, photoURL })
                            });

                        } else {
                            this._store.dispatch(firebaseActions.setUserLoaded(true));
                            this.clearUserData();
                            resolve(true);
                        }
                    });

                    if (firebase.apps.length) {
                        this.getPublicData();
                    }
                }
            } catch (e) {
                console.log('Firebase Error', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            this._store.dispatch(appActions.appState('error'));
            console.error('Aplication error: ' + reason);
            return Promise.resolve(null);
        });

        return appInit;

    }

    async refreshData() {
        await this.getPublicData();

        const { user } = this._store.getState().firebase;

        if (!user || (user && !user.uid))
            return null;

        this.getUserTicket(user.uid).then((tickets) => {
            this._store.dispatch(mystuffActions.setMyTickets(tickets));
            this.getMyStuffData(user.uid);
        }).catch(e => {
            console.error('Error getting user data', e.message);
        });
    }

    ifAdminOrAmbassador() {
        const { user, ambassadors } = this._store.getState().firebase;

        let retVal = false;

        if (user && user.administrator) {
            retVal = true;
        }

        if (!retVal && ambassadors && ambassadors.length && user && user.uid) {
            retVal = ambassadors.some(el => el.id && el.id === user.uid);
        }

        return retVal;
    }

    getUserToken() {
        return firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
            // Send token to your backend via HTTPS
            console.log('idToken', idToken);
            return idToken;
        }).catch(function (error) {
            // Handle error
        }) : null;

    }

    getDomain() {
        let domain = process.env.domain;
        if (domain) {
            if (domain.indexOf(':') === -1)
                return domain;
            let url = new URL(domain);
            return url.hostname;
        } else {
            let url = new URL(window.location.origin || '');
            return url.hostname;
        }
    }

    clearUserData() {
        const {
            user,
            auth,
            roles,
            channelList,
            stripeData,
            card,
            products,
            subscriptions,
            teasers
        } = this._store.getState().firebase;
        const {
            tickets
        } = this._store.getState().mystuff;

        auth && this._store.dispatch(firebaseActions.logOut());
        user && this._store.dispatch(firebaseActions.setUser(null));
        roles && this._store.dispatch(firebaseActions.setRoles(null));
        channelList && this._store.dispatch(firebaseActions.setChannelList([]));
        stripeData && this._store.dispatch(firebaseActions.setStripeData(null));
        card && this._store.dispatch(firebaseActions.setCard(null));
        products && this._store.dispatch(firebaseActions.setProducts([]));
        subscriptions && this._store.dispatch(firebaseActions.setSubscriptionList([]));
        teasers && this._store.dispatch(firebaseActions.setTeasers({}));
        tickets && this._store.dispatch(mystuffActions.setMyTickets([]));
    }

    checkShowFollowButton(userId) {
        const { user, channelList } = this._store.getState().firebase;
        if (channelList && channelList.length && userId) {
            let check = channelList.some(el => el.uid === userId);
            if (check) {
                return false;
            } else if (!check && user && user.uid) {
                if (user.uid === userId) {
                    return false;
                } else {
                    return true;
                }
            } else return true;
        } else if (user && user.uid && userId) {
            if (user.uid === userId) {
                return false;
            } else {
                return true;
            }
        } else return true;
    }

    getPublicDataRT() {
        const safeParse = (json) => {
            let ret = null;
            try {
                ret = JSON.parse(json);
            } catch (e) {
                console.error('Could not parse data', json)
            }
            return ret;
        }
        const loadCachedData = (snapshot) => {
            if (snapshot.exists()) {
                // console.log('----------------------- Cached data:', snapshot.val());
                let cached = snapshot.val(), ret = {};
                Object.entries(cached).forEach(([key, json_value]) => {
                    if (json_value && (typeof json_value === 'string' || json_value instanceof String)) {
                        ret[key] = safeParse(json_value)
                    } else if (json_value && isJson(json_value)) {
                        ret[key] = json_value
                    }
                });
                // console.log('----------------------- Parsed', ret);
                return ret;
            } else {
                console.log("No cached public data available");
                return null;
            }
        };
        const takeTwo = (error) => {
            return new Promise((resolve) => {
                setTimeout(() => firebase.database().ref('public/').get()
                    .then(loadCachedData)
                    .then((data) => {
                        return resolve(data);
                    })
                    .catch((error) => {
                        console.error(error);
                        return resolve(null);
                    }), 200);
            });
        };

        if (process.env && process.env.website) {
            return null;
        }

        return firebase.database().ref('public/').get()
            .then(loadCachedData)
            .then((result) => {
                if (result !== null) {
                    return result
                } else
                    return takeTwo({ message: 'About to try once more' })
            })
            .catch(takeTwo);

    }
    async getPublicData() {
        let pdata = await this.getPublicDataRT();
        if (pdata === null ||
            !pdata.categories || !pdata.categories.length
        ) {
            // Load from db
            let rq = firebase.functions().httpsCallable('getPublicData');
            pdata = await rq({ website: process.env.website }).then((doc) => {
                // console.log('getPublicData response:', doc ? doc.data : null)
                if (!doc)
                    return null;
                return doc.data;
            });
        }
        let { categories, featured_events, events, videos, hosts, defaults } = pdata;
        try {
            // Categories
            if (categories) {
                let ctgs = categories.filter((item) => {
                    return item.type && item.type == 'category'
                }).sort((a, b) => {
                    if (a.priority < b.priority) {
                        return -1;
                    }
                    if (a.priority > b.priority) {
                        return 1;
                    }
                    return 0;
                });
                this._store.dispatch(firebaseActions.getCategories(ctgs));
            }
            // featured_events
            if (featured_events) {
                let featured = [];
                featured_events.map((item) => {
                    if (item && item.id && ((!process.env.website) || (process.env.website && item.website && process.env.website === item.website))) {
                        featured.push(item.id);
                    }
                });

                this._store.dispatch(firebaseActions.getFeatured(featured));
            }
            // Load events
            if (events) {
                if (process.env.website) {
                    let filterEvents = events.filter((item) => {
                        if (item && process.env.website && item.website && process.env.website === item.website) {
                            return item;
                        }
                    });
                    this._store.dispatch(firebaseActions.getCalendarList(filterEvents));
                } else {
                    this._store.dispatch(firebaseActions.getCalendarList(events));
                }
            }
            // Load videos
            if (videos) {
                if (process.env.website) {
                    let filterVideos = videos.filter((item) => {
                        if (item && process.env.website && item.website && process.env.website === item.website) {
                            return item;
                        }
                    });
                    this._store.dispatch(firebaseActions.getVideos(filterVideos));
                } else {
                    this._store.dispatch(firebaseActions.getVideos(videos));
                }
            }

            if (defaults) {
                this._store.dispatch(firebaseActions.getDefaults(defaults));
            }

            // Load Hosts
            if (hosts) {
                hosts.sort((a, b) => a.username && a.username.toLowerCase && b.username && b.username.toLowerCase && a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1);
                const ambassadors = hosts.length ? hosts.filter(item => item.ambassador || item.premium) : [];
                this._store.dispatch(firebaseActions.setHosts(ambassadors, hosts));
            }

            this.getMyStuffData();
            return null;
        } catch (e) {
            console.error('Could not load public data', e.message, e);
        };
    }

    getSearchVideosFS(startDate, live, category, start) {

        const collection = `/posts`;
        const where = {
            fieldPath: 'startDate',
            optStr: '>',
            value: startDate
        }
        const w2 = {
            fieldPath: 'live',
            optStr: '==',
            value: live
        }
        const where2 = live === true || live === false ? w2 : null;
        const w3 = {
            fieldPath: 'cat',
            optStr: '==',
            value: category
        }
        const where3 = category ? w3 : null;
        const orderBy = {
            fieldPath: 'startDate',
            directionStr: 'desc'
        }
        const limit = 200;
        const startAfter = start ? start : null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchObject(collection, where, where2, where3, orderBy, limit, startAfter);
    }

    checkCustomers(user) {
        this.getOne('/customers', user.uid).then((res) => {
            if (res && res.data) {
                let response = res.data;
                this.getCard(response.stripeId);
                this.getProducts();
                this.readSubscriptions(response.stripeId);
                this._store.dispatch(firebaseActions.setStripeData(response));
                // console.log('Stripe user', response);
            } else if (user) {
                this.createCustomer(user);
                console.warn(
                    `No Stripe customer found in Firestore for user: ${user.uid}`
                );
            }
        }).catch(e => {
            console.error(`Could not check customers: ${e.message}`)
        });
    }

    sendInquireEmail(broadcaster_name, broadcaster_email, inquiry_name, email, daytime_contact, message) {
        let ref = firebase.functions().httpsCallable('sendInquireEmailFS');
        let that = this;
        return ref({
            broadcaster_name: broadcaster_name,
            broadcaster_email: broadcaster_email,
            inquiry_name, inquiry_email: email,
            daytime_contact: daytime_contact,
            message: message,
            website: process.env.website
        }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send Inquire email: ${e.message}`)
        });
    }

    sendFaqQuestionEmail(subject, message) {
        let ref = firebase.functions().httpsCallable('sendFaqQuestionEmailFS');
        return ref({
            subject: subject,
            message: message,
            website: process.env.website
        }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send FAQ email: ${e.message}`)
        });
    }

    sendFeedback(data) {
        let ref = firebase.functions().httpsCallable('sendFeedback');
        return ref(data).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not send feedback: ${e.message}`)
        });
    }

    getVideoLinks(item, download = false, newRecording = false) {
        let ref = firebase.functions().httpsCallable('getVideoLinks');
        return ref({ alias: item.id, authorId: item.uid, download, newRecording, website: process.env.website }).then((result) => {
            return result.data;
        }).catch(e => {
            console.error(`Could not get video links: ${e.message}`)
        });
    }

    sendSupportMail(userId, type) {
        let ref = firebase.functions().httpsCallable('sendSupportMail');
        return ref({ userId, type }).then((result) => {
            return result;
        });
    }

    setHostPrivileges(userId) {
        let ref = firebase.functions().httpsCallable('setHostPrivileges');
        return ref({ userId }).then((result) => {
            return result;
        });
    }

    getSoldTicketFS(eventId) {

        const collection = `/tickets`;
        const where = {
            fieldPath: 'eventId',
            optStr: '==',
            value: eventId
        }
        const where2 = null
        const where3 = null;
        const orderBy = null
        const limit = 1000;
        const startAfter = null;

        return this.getAllSearchObject(collection, where, where2, where3, orderBy, limit, startAfter).then((res) => {
            let data = {
                eventTicketCount: 0,
                streamTicketCount: 0
            }

            if (res && res.data && res.data.length) {
                data.eventTicketCount = res.data.filter(item => item.type && item.type === "event-ticket" && ((!item.role) || (item.role && ((item.role === 'audience') || (item.role === 'listener'))))).length;
                data.streamTicketCount = res.data.filter(item => item.type && item.type === "stream-ticket" && ((!item.role) || (item.role && ((item.role === 'audience') || (item.role === 'listener'))))).length;
            }

            return data;
        });
    }

    getFilteredUsersFS(email) {

        const collection = `/users`;
        const where = {
            fieldPath: 'email',
            optStr: '>=',
            value: email
        }
        const where2 = {
            fieldPath: 'email',
            optStr: '<=',
            value: email + '\uf8ff'
        }

        const where3 = null;
        const orderBy = {
            fieldPath: 'email',
        }
        const limit = 1000;
        const startAfter = null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchObject(collection, where, where2, where3, orderBy, limit, startAfter);
    }

    getUserTicket(uid, streamId = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('getUserTickets');
                return readObjectRef({ streamId }).then((result) => {
                    console.log('User tickets received');
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });

        return objects;
    }

    reserveTicket(item) {
        let reserve = new Promise((resolve, reject) => {
            try {
                let reserveTicketRef = firebase.functions().httpsCallable('reserveTicketV2');
                return reserveTicketRef({ item: { ...item, website: item.website || process.env.website || '' }, id: uuidv4() }).then((result) => {
                    // console.log('Reserve Ticket', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Reserve Ticket', e);
                reject(e.message);
            }
        });
        return reserve;
    }

    follow(uid, name, pic) {
        // We are trying to subscribe to this streamer's channel

        let ref = firebase.functions().httpsCallable('addChannel');
        let that = this;
        ref({ uid: uid, displayName: name, picture: pic }).then(() => {
            console.log('Added channel');
            this.getMyChannels();
        });
    }

    unfollow(uid) {
        // We are trying to subscribe to this streamer's channel

        let ref = firebase.functions().httpsCallable('removeChannel');
        let that = this;
        ref({ uid: uid }).then((res) => {
            console.log('Remove channel');
            let btn = document.getElementById(`btn-unfollow-${uid}`);
            if (btn) {
                btn.style.pointerEvents = 'auto';
                btn.style.opacity = '1';
            }
            this.getMyChannels();
        });
    }

    getMyChannels() {
        let channel = new Promise((resolve, reject) => {
            try {
                let getMyChannelsRef = firebase.functions().httpsCallable('getMyChannels');
                return getMyChannelsRef().then((result) => {
                    //console.log("RESULT:", result);
                    if (result.data) {
                        this._store.dispatch(firebaseActions.setChannelList(result.data));
                    }
                    // console.log('Results received');
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get List', e);
                reject(e.message);
            }
        });
        return channel;
    }

    logIn(value) {
        if (!firebase.auth().currentUser) {
            if (process.env.platform && process.env.platform === 'mobile') {
                // if (value === 'google') {
                //     cfaSignInGoogle().subscribe();
                // } else if (value === 'apple') {
                //     cfaSignInApple().subscribe();
                // }
            } else {
                let provider = value;
                if (provider === 'google') {
                    provider = new firebase.auth.GoogleAuthProvider();
                } else if (provider === 'yahoo') {
                    provider = new firebase.auth.OAuthProvider('yahoo.com');
                } else if (provider === 'apple') {
                    provider = new firebase.auth.OAuthProvider('apple.com')
                } else {
                    provider = new firebase.auth.FacebookAuthProvider();
                }

                return firebase.auth().signInWithPopup(provider).then((result) => {
                    // var token = result.credential.accessToken;
                    // var user = result.user;
                    console.log('login');
                })
            }
        } else {
            return firebase.auth().signOut();
        }
    }

    signInWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
                console.log('login signInWithEmailAndPassword');
            })
        } else {
            firebase.auth().signOut();
            return Promise.resolve();
        }
    }

    createUserWithEmailAndPassword(email, password) {
        if (email && password) {
            return firebase.auth().createUserWithEmailAndPassword(email, password).then((result) => {
                console.log('create createUserWithEmailAndPassword');
            });
        } else {
            return Promise.resolve();
        }
    }

    sendPasswordResetMail(email) {
        let sendPasswordResetRef = firebase.functions().httpsCallable('sendPasswordResetMail');
        return sendPasswordResetRef({ email }).then((result) => {
            console.log('result sendPasswordResetMail', result);
            return;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    verifyEmail() {
        console.log('call verify email');
        return firebase.auth().currentUser.sendEmailVerification().then(() => {
            console.log('Email verification sent!');
        });
    }

    changeUserPassword(path, password) {
        let changeUserPasswordRef = firebase.functions().httpsCallable('changeUserPassword');
        return changeUserPasswordRef({ path, password }).then((result) => {
            console.log('result changeUserPassword', result);
            return;
        });
    }

    /**
     * Start account pairing
     * @param err Error returned by signInWithPopup
     * @description
     * At this point, you should let the user know that they already have an \
     * account with a different provider, and validate they want to sign in
     * with the new provider.
     * Note: Browsers usually block popups triggered asynchronously, so in
     * real app, you should ask the user to click on a "Continue" button
     * that will trigger signInWithPopup().
     */
    pairAccounts(err) {
        if (err) {
            // User's email already exists.
            console.log('About to start pairing', err)
            // The pending credential.
            let pendingCred = err.credential;
            // The provider account's email address.
            let email = err.email;
            console.log('-++++++++++++++++', pendingCred, email)
            // Get the sign-in methods for this email.
            return firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
                console.log('++++++++++++++++', methods[0], methods)
                // If the user has several sign-in methods, the first method
                // in the list will be the "recommended" method to use.
                let provider;
                switch (methods[0]) {
                    case 'google.com':
                        provider = new firebase.auth.GoogleAuthProvider();
                        break;
                    case 'microsoft.com':
                        provider = new firebase.auth.OAuthProvider('microsoft.com');
                        break;
                    case 'apple.com':
                        provider = new firebase.auth.OAuthProvider('apple.com')
                        break;
                    case 'facebook.com':
                        provider = new firebase.auth.FacebookAuthProvider();
                        break;

                    case 'password': {
                        // TODO: Ask the user for their password.
                        // In real scenario, you should handle this asynchronously.
                        // var password = promptUserForPassword();
                        // firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
                        //     return result.user.linkWithCredential(pendingCred);
                        // }).then(() => {
                        //     // Facebook account successfully linked to the existing user.
                        //     goToApp();
                        // });
                        // return;
                        break;
                    }
                    default:
                        throw (new Error('Unknown auth provider'));
                }
                return firebase.auth().signInWithPopup(provider).then(result => {
                    // Note: Identity Platform doesn't control the provider's sign-in
                    // flow, so it's possible for the user to sign in with an account
                    // with a different email from the first one.

                    // Link the Facebook credential. We have access to the pending
                    // credential, so we can directly call the link method.
                    result.user.linkWithCredential(pendingCred).then(usercred => {
                        // Success.
                        // goToApp();
                    });
                });
            });
        } else {
            console.error('Could not pair accounts. Missing pending account');
            firebase.auth().signOut();
            throw (new Error('Could not pair accounts. Missing pending account'));
        }
    }

    logOut() {
        let logOut = new Promise((resolve, reject) => {
            try {
                firebase.auth().signOut();
                resolve(true)
            } catch (e) {
                console.log('FIREBASE ERROR', e);
                reject(e.message);
            }
        }).catch((reason) => {
            // Log the rejection reason
            console.error('Log out error: ' + reason);
            return Promise.resolve();
        });

        return logOut;
    }

    /**
     * Get current user products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    getUserProducts() {
        let getUserProductsRef = firebase.functions().httpsCallable('getUserProducts');
        return getUserProductsRef({}).then((result) => {
            // console.log('RESULT user products', result.data);
            return result.data;
        }).catch((error) => {
            return null;
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    /**
     * Get current user products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    getUserProductsById(id) {
        let getUserProductsRef = firebase.functions().httpsCallable('getUserProducts');
        return getUserProductsRef({ id: id }).then((result) => {
            // console.log('RESULT user products', result.data);
            return result.data;
        }).catch((error) => {
            return null;
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    /**
     * Save current user products
     * @param userId
     * @param products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    saveUserProducts(userId, products) {
        let saveUserProductsRef = firebase.functions().httpsCallable('saveUserProducts');
        var postData = {
            userId,
            products
        };
        return saveUserProductsRef(postData).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
            throw error;
        });

    }

    /**
     * Save current user products
     * @param userId
     * @param products
     * @returns {Promise<firebase.functions.HttpsCallableResult>}
     */
    cancelHostingSubscription(user_privileges_id) {
        let cancelHostingSubscriptionRef = firebase.functions().httpsCallable('cancelHostingSubscription');
        var postData = {
            id: user_privileges_id
        };
        return cancelHostingSubscriptionRef(postData).then((result) => {
            // console.log('RESULT cancelHostingSubscription', result.data);
            return result;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });

    }

    getOne(collection, id) {
        let readObjectRef = firebase.functions().httpsCallable('readObject');
        return readObjectRef({ collection, id }).then((result) => {
            // console.log("RESULT:", result, id);
            const resultJson = Object.assign({}, result.data, { id: id });
            return { data: resultJson };
            // return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getEvent(alias, website) {
        let getEventRef = firebase.functions().httpsCallable('getEventData');
        return getEventRef({ alias, website }).then((result) => {
            return result;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getAllSearchObject(collection, where, where2, where3, orderBy, limit = 200, startAfter = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                // console.log("About to get search data from:", { collection, where, where2, where3, orderBy, limit, startAfter });
                let readObjectRef = firebase.functions().httpsCallable('readSearchObjects');
                return readObjectRef({ collection, where, where2, where3, orderBy, limit, startAfter }).then((result) => {
                    // console.log('Search result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    getAllSearchUsers(where, where2, where3, orderBy, limit = 200, startAfter = null) {
        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('readSearchUsers');
                return readObjectRef({ where, where2, where3, orderBy, limit, startAfter }).then((result) => {
                    // console.log('Search result received')//, result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    add(collection, data, id) {
        //console.log("About to add data to:", { collection });
        // return null;
        let createObjectRef = firebase.functions().httpsCallable('createObject');
        return createObjectRef({ collection, data, id }).then((result) => {
            //console.log("add RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    update(collection, data, id) {
        // console.log("About to update data to:", { collection, data, id });
        // return null;
        let opRef = firebase.functions().httpsCallable('updateObject');
        return opRef({ collection, data, id }).then((result) => {
            // console.log("update RESULT:", result);
            return result.data;
        }).catch((error) => {
            // var code = error.code;
            // var message = error.message;
            // var details = error.details;
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    checkPass(password) {
        // console.log("About to check data from:", { password });
        let checkPasswordRef = firebase.functions().httpsCallable('checkPassword');
        return checkPasswordRef({ password }).then((result) => {
            // console.log("Password Check Result:", result);
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function', error);
        });
    }

    getUserHostingPrivileges() {
        let rq = firebase.functions().httpsCallable('getUserHostingPrivileges');
        return rq(/*user*/).then((doc) => {
            // console.log('getUserPrivileges response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;
            // console.log('getUserPrivileges response:', udata)
            return udata;
        });
    }

    getUserPrivileges() {
        let rq = firebase.functions().httpsCallable('getUserPrivileges');
        return rq(/*user*/).then((doc) => {
            // console.log('getUserPrivileges response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;
            // console.log('getUserPrivileges response:', udata)
            return udata;
        });
    }

    getPrivileges() {
        const { user } = this._store.getState().firebase;

        let uid = user && user.uid ? user.uid : user && user.id ? user.id : null;

        if (uid) {
            return this.getOne('/user_privileges', uid).then((res) => {
                let retValue = res.data;

                if (retValue && retValue.id) {
                    delete retValue['id'];
                }

                return retValue;
            })
        } else return {};
    }

    getUserData(user, auth) {
        if (!user) {
            this._store.dispatch(firebaseActions.setUserLoaded(true));
            return Promise.resolve(null);
        }
        const userDetails = {
            uid: user.uid ? user.uid : null,
            displayName: user.displayName ? user.displayName : null,
            photoURL: user.photoURL ? user.photoURL : null,
            email: user.email ? user.email : null,
            emailVerified: user.emailVerified ? user.emailVerified : null,
            isAnonymous: user.isAnonymous ? user.isAnonymous : null,
            providerData: user.providerData ? user.providerData : null
        }
        if (!user.uid) {
            // Should not be here
            console.warn('User without uid found!', user)
            this._store.dispatch(firebaseActions.setUser(userDetails));
            this._store.dispatch(firebaseActions.setUserLoaded(true));
            return Promise.resolve(userDetails);
        }
        let rq = firebase.functions().httpsCallable('getUserData');
        return rq(user).then((doc) => {
            // console.log('getUserData response:', doc)
            if (!doc)
                return null;
            let udata = doc.data;

            const { logInSignup } = this._store.getState().app;

            // console.log('getUserData response:', udata)
            if (udata && udata.roles) {
                this._store.dispatch(firebaseActions.setRoles(udata.roles));
            }
            if (auth) {
                this._store.dispatch(firebaseActions.logIn(true));
            } //else
            if (udata && udata.user) {
                let combined = Object.assign({}, userDetails, udata.user);
                this._store.dispatch(firebaseActions.setUser(combined));
                this._store.dispatch(firebaseActions.setUserLoaded(true));

                if (logInSignup)
                    this.pushToSignup();

                return combined;
            }
            return null;
        }).catch(e => {
            console.error('Could not load user data', e.message);
        });
    }

    getUserFS() {
        let rq = firebase.functions().httpsCallable('getUserData');
        return rq().then((doc) => {
            // console.log('getUserData response:', doc)
            if (!doc)
                return null;

            let data = doc.data;

            if (data && data.user) {
                return { data: data.user };
            } else return null;
        }).catch(e => {
            console.error('Could not load user data', e.message);
        });
    }

    updateUserData(user) {
        // Set nfb data,
        if (user && user.uid) {
            return this.getUserFS().then((res) => {
                if (res && res.data) {
                    let nfb_user_data = res.data;
                    // console.log('NFB user data', nfb_user_data)
                    return Object.assign({}, user, nfb_user_data);
                }
                return user;
            }).then(udata => {
                this._store.dispatch(firebaseActions.setUser(udata));
                this._store.dispatch(firebaseActions.setUserLoaded(true));
                return udata;
            }).catch(e => {
                //  Check if user exists
            });
        }
        return Promise.resolve(user);
    }

    handleImageUpload(imageFile, maxWidthOrHeight = null) {
        let img = new Promise((resolve, reject) => {
            let size = imageFile.size / 1024 / 1024;
            // console.log('originalFile', imageFile); // true
            // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            // console.log(`originalFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`);

            if (size > 1 || maxWidthOrHeight) {
                var options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: maxWidthOrHeight ? maxWidthOrHeight : 1920,
                    useWebWorker: true
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        let size = compressedFile.size / 1024 / 1024;
                        // console.log('compressedFile', compressedFile); // true
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                        // console.log(`compressedFile size ${size && size.toFixed ? size.toFixed(2) : 0} MB`); // smaller than maxSizeMB

                        resolve(compressedFile); // write your own logic
                    })
                    .catch(function (error) {
                        reject(error.message);
                    });
            } else {
                resolve(imageFile)
            }
        })
        return img;
    }

    addImageToStorage(file, type, id, maxWidthOrHeight = null) {
        let storageAdd = new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            this.handleImageUpload(file, maxWidthOrHeight).then((resizeImg) => {
                if (resizeImg) {
                    // Create the file metadata
                    var metadata = {
                        contentType: resizeImg && resizeImg.type ? resizeImg.type : 'image/jpeg'
                    };

                    let folder = 'profile_pictures/';

                    if (type && type === 'event') {
                        folder = 'events_pictures/'
                    } else if (type && type === 'layout') {
                        folder = 'layout_pictures/'
                    }
                    // Upload file and metadata to the object 'images/mountains.jpg'
                    var uploadTask = storageRef.child(folder + id).put(resizeImg, metadata);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                        function (snapshot) {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            // console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    // console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    // console.log('Upload is running');
                                    break;
                            }
                        }, (error) => {
                            reject(error)
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;

                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;

                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                            }
                        }, function () {
                            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                                // console.log('File available at', downloadURL);
                                return resolve(downloadURL);
                            });
                        });
                }
            });
        })
        return storageAdd;
    }

    updateUser(uid, data) {
        let callableRef = firebase.functions().httpsCallable('updateUser');
        return callableRef({ userId: uid, userData: data }).then((result) => {
            return result.data;
        });
    }

    checkUserTickets(streamId, authorId, website = null) {

        const { user } = this._store.getState().firebase;
        if (!user)
            return Promise.resolve(false);
        const collection = `/tickets`;
        const where = {
            fieldPath: 'eventId',
            optStr: '=',
            value: streamId
        }

        const where2 = {
            fieldPath: 'userId',
            optStr: '=',
            value: user.uid
        }

        const where3 = null;
        // const where3 = {
        //     fieldPath: 'paymentStatus',
        //     optStr: 'in',
        //     value: ['success', 'cancelled']
        // }
        const orderBy = null
        const limit = 100;

        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('checkUserTicket');
                return readObjectRef({ streamId: streamId, authorId: authorId, website: website }).then((result) => {
                    // console.log('Ticket received', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;

    }

    signInWithCustomToken(token) {
        if (token) {
            firebase.auth().signInWithCustomToken(token).then((userCredential) => {
                console.log('signInWithCustomToken', userCredential)
                // Signed in
                var user = userCredential.user;
                // ...
            })
                .catch((error) => {
                    console.error('signInWithCustomToken', error);
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ...
                });
        } else {

        }
    }

    verifyToken(data) {
        let ref = firebase.functions().httpsCallable('verifyToken');
        return ref(data).then((result) => {
            if (result && result.data) {
                this.signInWithCustomToken(result.data);
            }
        });
    }

    cancelUserSubscription(authorId, ticketType) {
        let objects = new Promise((resolve, reject) => {
            try {
                let readObjectRef = firebase.functions().httpsCallable('cancelUserSubscription');
                return readObjectRef({ authorId: authorId, ticketType: ticketType }).then((result) => {
                    // console.log('Subscription cancelled', result);
                    resolve(result)
                }).catch((error) => {
                    reject(error);
                    // var code = error.code;
                    // var message = error.message;
                    // var details = error.details;
                    console.error('There was an error when calling the Cloud Function', error);
                });
            } catch (e) {
                console.error('Firebase Error Get Objects', e);
                reject(e.message);
            }
        });
        return objects;
    }

    cancelUserTicket(id) {
        return this.update('/tickets', { paymentStatus: 'cancelled' }, id);
    }

    injectCalendarEvent(event) {
        const { calendarList, videos } = this._store.getState().firebase;

        let eventCollections = calendarList;
        let streams = videos && videos.length && event && event.id ? videos.filter((stream) => stream && stream.id && stream.id === event.id) : null;
        let isStream = streams && streams.length ? streams[0] : null;

        if (event && event.visibility && event.visibility === "public" && ((!process.env.website) || (process.env.website && event.website && process.env.website === event.website)) && ((!isStream) || (isStream && isStream.status && isStream.status !== 'ended' && isStream.status !== 'cancelled'))) {
            const updatedCollections = eventCollections.filter(ev => ev.id != event.id);
            updatedCollections.push(event);
            this._store.dispatch(firebaseActions.getCalendarList(updatedCollections));
        }
    }

    listenToLiveUpdates() {
        var luRef = firebase.database().ref('liveupdates/event');
        luRef.off();
        luRef.on('value', (data) => {
            this.injectCalendarEvent(data.val());
            console.log('New event injected', data.val());
        });
    }

    listenToSoldTicket(item, setNumber) {
        let eventSoldNumber = 0, streamSoldNumber = 0;
        var eventSoldRef = firebase.database().ref(`calendar/${item.id}/tickets/sold`);
        eventSoldRef.off();
        eventSoldRef.on('value', async function (data) {
            eventSoldNumber = data.val() ? data.val() : 0;
            setNumber(eventSoldNumber, 'event');
        });

        if (item && ((item.type && item.type === 'stream') || (item.status && item.status === 'ended'))) {
            var postSoldRef = firebase.database().ref(`posts/${item.id}/tickets/sold`);
            postSoldRef.off();
            postSoldRef.on('value', async function (data) {
                streamSoldNumber = data.val() ? data.val() : 0;
                setNumber(streamSoldNumber, 'stream');
            });
        }
    }

    stopListeningToSoldTicket(item) {
        const { user } = this._store.getState().firebase;
        if (user && user.uid) {
            var eventSoldRef = firebase.database().ref(`calendar/${item.id}/tickets/sold`);
            eventSoldRef.off();
            if (item && ((item.type && item.type === 'stream') || (item.status && item.status === 'ended'))) {
                var streamSoldRef = firebase.database().ref(`posts/${item.id}/tickets/sold`);
                streamSoldRef.off();
            }
        }
    }
    pushToSignup() {
        this._store.dispatch(nativePush('/signup?checkout=1'));
    }

    getUsersFS(start, end) {
        const where = {
            fieldPath: 'sortId',
            optStr: '<',
            value: end ? end : 0
        }

        const where2 = {
            fieldPath: 'broadcaster',
            optStr: '==',
            value: true
        }

        const orderBy = {
            fieldPath: 'sortId'
        }
        const limit = 500;
        const startAfter = start ? start : null;
        // console.log("About to get data from:", { collection, where, orderBy, limit });

        return this.getAllSearchUsers(where, where2, null, orderBy, limit, startAfter);
    }

    sortCalendarEvents(array) {
        const { user } = this._store.getState().firebase;
        let uid = null;
        if (user && user.uid) {
            uid = user.uid;
        }

        let eventCollections = {
            upcoming: [],
            my: [],
            scheduled: [],
            featured: [],
            all: []
        }

        if (array && array.length) {
            let now = Date.now();

            eventCollections.featured = this.prepareFeaturedEvents(true)

            array.map((item) => {
                let period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;
                eventCollections.all.push(item);
                if (item && item.status && ((item.status !== 'ended' && item.startDate + period > now) || (item.status === 'live' || item.status === 'started')) && (!item.visibility || item.visibility === "public")) {
                    // tickets are the real display filter here, private events should not be accessible by clients who didn't buy tickets
                    eventCollections.upcoming.push(item);
                }
                if (item && item.uid === uid) {
                    eventCollections.my.push(item);
                }
                if (item && item.visibility && item.visibility === "public") {
                    eventCollections.scheduled.push(item);
                }
            });

            let compareFn = (a, b) => {
                if (a.startDate < b.startDate) {
                    return -1;
                }
                if (a.startDate > b.startDate) {
                    return 1;
                }
                return 0;
            }

            eventCollections.upcoming.sort(compareFn);

            // console.log('About to sort calendar events', eventCollections)
            this._store.dispatch(firebaseActions.updatedEvents(eventCollections));
            this.getMyStuffData();
        } else {
            this._store.dispatch(firebaseActions.updatedEvents(eventCollections));
        }
    }

    sortingVideos(array) {
        const { user } = this._store.getState().firebase;
        const { tickets } = this._store.getState().mystuff;
        let uid = null;
        if (user && user.uid) {
            uid = user.uid;
        }

        let videoCollections = {
            live: [],
            videos: []
        };

        if (array && array.length) {
            array.map((item) => {
                if ((item.live) || (item.status && item.status === 'started')) {
                    videoCollections.live.push(item);
                } else {
                    let cat = item.cat ? item.cat.toLowerCase() : 'general'
                    if (!videoCollections[cat])
                        videoCollections[cat] = [];
                    if (item.archiveEnabled)
                        videoCollections[cat].push(item);
                    if (item && item.visibility && item.visibility === "public" || item && item.visibility && uid && item.uid && (item.uid === uid || this.checkCoHost(item, uid) || this.checkGuest(item, uid) || this.checkModerator(item, uid)) && item.visibility === "private" || item && !item.visibility || tickets[item.id]) {
                        videoCollections.videos.push(item);
                    }
                }
            });
            let compareFn = (a, b) => {
                if (a.startDate > b.startDate) {
                    return -1;
                }
                if (a.startDate < b.startDate) {
                    return 1;
                }
                return 0;
            }
            // Sort by date
            for (const cat in videoCollections) {
                videoCollections[cat].sort(compareFn);
            }

            videoCollections.live.sort(compareFn);
            videoCollections.videos.sort(compareFn);
        }

        this._store.dispatch(firebaseActions.updatedVideos(videoCollections));
        this.getMyStuffData();
    }

    getMyStuffData(user_id) {
        const { events, videos, live, user, channelList, hosts } = this._store.getState().firebase;
        const { tickets } = this._store.getState().mystuff;

        let uid = user_id || null;

        if (!uid && user && user.uid) {
            uid = user.uid;
        }

        if (!uid)
            return;

        let authorIds = channelList ? channelList.map((item) => {
            return item.uid;
        }) : null;

        let upcomingEvents = [], pastEvents = [], vault = [], live_streams = [], private_vault = [], hostingEvents = [];
        let hostedEvents = [], followingEvents = [], hostSubscriptions = [], subscribedToHosts = [], hostsIds = null, now = Date.now();

        if (tickets) {
            let tarray = Object.values(tickets);

            hostSubscriptions = tarray.filter((item) =>
                item.type == 'subscription' && (item.streamId == '*' || item.showId == '*'));
        }

        if (hostSubscriptions && hostSubscriptions.length && hosts && hosts.length) {
            hostsIds = hostSubscriptions.map(item => item.authorId)
            subscribedToHosts = hosts.filter(a => hostsIds.indexOf(a.id) !== -1);
        }

        if (live && live.length) {
            live.map((item) => {
                if (!tickets[item.id])
                    return;
                live_streams.push(item);
            });
        }

        if (videos && videos.length) {
            videos.map((item) => {
                if (!tickets[item.id])
                    return;
                if (
                    (tickets[item.id].authorId === uid)
                    || (tickets[item.id].role === 'host')
                    || (tickets[item.id].role === 'cohost')
                    || (tickets[item.id].role === 'guest')
                    || (tickets[item.id].role === 'guest_speaker')
                    || (tickets[item.id].role === 'moderator')
                ) {
                    if (item.archiveEnabled)
                        vault.push(item);
                    else
                        private_vault.push(item);
                } else {
                    pastEvents.push(item);
                }
            });
        }

        if (events && events.all && events.all.length) {
            events.all.map((item) => {
                let period = (item && item.estDuration ? item.estDuration : 60) * 60 * 1000;

                if (authorIds && authorIds.indexOf(item.uid) !== -1) {
                    if (((item.startDate + period > now) || (item.status && (item.status === 'live' || item.status === 'started'))) && item.visibility && item.visibility === 'public') {
                        followingEvents.push(item);
                    }
                }

                let ticket = tickets[item.id] || hostSubscriptions.find(t => t.authorId === item.uid);

                if (!ticket)
                    return;

                if (ticket.authorId === uid || (ticket.userId && ticket.userId === uid && ticket.role && (ticket.role === 'cohost' || ticket.role === 'guest' || ticket.role === 'guest_speaker' || ticket.role === 'moderator') && ticket.status && ticket.status === 'confirmed')) {
                    if (item.status && ((item.status !== 'ended' && item.startDate + period > now) || (item.status === 'live' || item.status === 'started'))) {
                        hostingEvents.push(item);
                    } else {
                        hostedEvents.push(item);
                    }
                } else {
                    if (item.status && ((item.status !== 'ended' && item.startDate + period > now) || (item.status === 'live' || item.status === 'started'))) {
                        upcomingEvents.push(item);
                    }
                }
            });
        }

        let compareFn = (a, b) => {
            if (a.startDate < b.startDate) {
                return -1;
            }
            if (a.startDate > b.startDate) {
                return 1;
            }
            return 0;
        }

        hostingEvents.sort(compareFn);
        upcomingEvents.sort(compareFn);
        live_streams.sort(compareFn);
        followingEvents.sort(compareFn);

        let myStuff = {
            vault,
            private_vault,
            live: live_streams,
            upcomingEvents,
            pastEvents,
            hostingEvents,
            hostedEvents,
            followingEvents,
            hostSubscriptions,
            subscribedToHosts
        }
        this._store.dispatch(mystuffActions.setMyStuff(myStuff));
    }

    prepareFeaturedEvents(noStore) {
        const { events, live, videos } = this._store.getState().firebase;
        //console.log('About to get featured events', featured, events)

        let featuredEvents = events && events.upcoming ? events.upcoming.filter((item) => {
            if (item && item.featured) {
                return item;
            }
        }) : [];

        let featuredLive = live && live.length ? live.filter((item) => {
            if (item && item.featured && ((item.live) || (item.status && item.status === 'started')) && item.archiveEnabled) {
                return item;
            }
        }) : [];

        let featuredStream = videos && videos.length ? videos.filter((item) => {
            if (item && item.featured && item.archiveEnabled) {
                return item;
            }
        }) : [];
        //console.log('featuredEvents', featuredEvents);
        let newFeatured = [...featuredLive, ...featuredEvents, ...featuredStream]

        newFeatured = newFeatured.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.id === item.id
            ))
        );
        events.featured = newFeatured;

        if (noStore) {
            return newFeatured;
        } else {
            this._store.dispatch(firebaseActions.updatedEvents(events));
            return null;
        }
    }

    checkPaymentMethod(uid) {
        let callableRef = firebase.functions().httpsCallable('checkPaymentMethod');
        return callableRef({ uid: uid }).then((result) => {
            console.log('RESULT checkPaymentMethod', result.data);
            let { paymentMethod } = (result && result.data) ? result.data : null;
            return paymentMethod;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function checkPaymentMethod', error);
        });
    }

    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    createPaymentIntents(paymentData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('createPaymentIntentsV2');
        return callableRef(paymentData).then((result) => {
            console.log('RESULT createPaymentIntents', result.data);
            let { clientSecret } = (result && result.data) ? result.data : null;
            this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return clientSecret;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function createPaymentIntents', error);
            this._store.dispatch(paymentActions.failedPaymentIntents(error));
            return null;
        });
    }

    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    createPaymentIntentsOrThrowError(paymentData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('createPaymentIntentsV2');
        return callableRef(paymentData).then((result) => {
            console.log('RESULT createPaymentIntents', result.data);
            let { clientSecret } = (result && result.data) ? result.data : null;
            this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return clientSecret;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function createPaymentIntents', error);
            this._store.dispatch(paymentActions.failedPaymentIntents(error));
            throw error;
            return null;
        });
    }


    /**
     * Create payment intents for supplied payment data
     * @param paymentData object e.g {type 'ticket': streamId:'someid'}
     * @returns {Promise<void>}
     */
    saveCalendarEvent(saveData) {
        return this.isUserLogged().then((res) => {
            if (res) {
                let callableRef = firebase.functions().httpsCallable('saveCalendarEventFSV2');
                return callableRef(saveData).then((result) => {
                    return result.data;
                }).catch((error) => {
                    console.error('There was an error when calling the Cloud Function saveCalendarEventFS', error);
                    throw error;
                });
            }
        }).catch((e) => {
            console.error('User not logged', e);
            throw e;
        });
    }

    saveStream(saveData) {
        const { user } = this._store.getState().firebase;
        let callableRef = firebase.functions().httpsCallable('saveStreamFS');
        return callableRef(saveData).then((result) => {
            // console.log('RESULT saveStreamFS', result.data);
            let { eventId } = (result && result.data) ? result.data : null;
            // this._store.dispatch(paymentActions.setClientSecret(clientSecret));
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function saveStreamFS', error);
            // this._store.dispatch(paymentActions.failedPaymentIntents(error));
        });
    }

    cancelEventOrPost(data) {
        let callableRef = firebase.functions().httpsCallable('deleteCalendarEventFSV2');
        return callableRef(data).then((result) => {
            return result.data;
        }).catch((error) => {
            console.error('There was an error when calling the Cloud Function ', error);
        });
    }

    hideCohostVideo(data) {
        let callableRef = firebase.functions().httpsCallable('hideCohostVideoV2');
        return callableRef(data).then((result) => {
            return result.data;
        })
    }

    subscribeToFanoutStateChanges(fanoutId, callback = null) {
        if (fanoutId) {
            let fanoutRef = firebase.database().ref(`fanout/instances/${fanoutId}/state`);
            fanoutRef.off();
            fanoutRef.on('value', (data) => {
                if (data.exists()) {
                    this._store.dispatch(appActions.appFanoutState(data.val()));
                    if (callback)
                        callback(data.val());
                }
            });
        }
    }

    unsubscribeToFanoutStateChanges(fanoutId) {
        if (fanoutId)
            firebase.database().ref(`fanout/instances/${fanoutId}/state`).off();
    }

    subscribeToSignallingClientChanges(uuid, alias, callback) {
        let client_str = "signalling/to_client/" + alias + "/" + uuid;
        let signallingRef = firebase.database().ref(client_str);
        signallingRef.off();
        signallingRef.on('value', (data) => {
            let obj = {};
            data.forEach((snapshot) => {
                obj[snapshot.key] = snapshot.val();
            });
            if (Object.keys(obj).length)
                callback(obj);
        });
    }

    unsubscribeFromSignallingClientChanges(uuid, alias) {
        if (uuid && alias)
            firebase.database().ref("signalling/to_client/" + alias + "/" + uuid).off();
    }

    getEventCardPicture(event) {
        let cardPicture = null;

        if (event) {
            if (event.branding && event.branding.eventSquareImage) {
                cardPicture = event.branding.eventSquareImage;
            } else if (event.branding && event.branding.eventCardImage) {
                cardPicture = event.branding.eventCardImage;
            } else if (event.archiveCustomCover) {
                cardPicture = event.archiveCustomCover;
            } else if (event.poster) {
                cardPicture = event.poster;
            } else if (event.authorPic) {
                cardPicture = event.authorPic;
            }
        }

        return cardPicture;
    }

    getEventPagePicture(event, orientation) {
        let eventPagePicture = null;

        if (event) {
            if (orientation && orientation === 'landscape' && event.branding && event.branding.horizontalEventPageImage) {
                eventPagePicture = event.branding.horizontalEventPageImage;
            } else if (orientation && orientation === 'portrait' && isMobileWithoutTablet() && event.branding && event.branding.verticalEventPageImage) {
                eventPagePicture = event.branding.verticalEventPageImage;
            } else if (event.archiveCustomCover) {
                eventPagePicture = event.archiveCustomCover;
            } else if (event.poster) {
                eventPagePicture = event.poster;
            } else if (event.authorPic) {
                eventPagePicture = event.authorPic;
            }
        }

        return eventPagePicture;
    }

    getEventHorizontalPicture(event) {
        let horizontalPicture = null;

        if (event) {
            if (event.branding && event.branding.horizontalEventPageImage) {
                horizontalPicture = event.branding.horizontalEventPageImage;
            } else if (event.archiveCustomCover) {
                horizontalPicture = event.archiveCustomCover;
            } else if (event.poster) {
                horizontalPicture = event.poster;
            } else if (event.authorPic) {
                horizontalPicture = event.authorPic;
            }
        }

        return horizontalPicture;
    }

    getEventPoster(event) {
        let posterValue = null;

        if (event) {
            if (event.branding && event.branding.horizontalEventPageImage) {
                posterValue = event.branding.horizontalEventPageImage;
            } else {
                posterValue = poster;
            }
        }

        return posterValue;
    }

    checkCoHost(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.cohosts && item.cohosts.length) {
            item.cohosts.map((cohost) => {
                if (cohost && cohost.id && cohost.id === userUid && cohost.status && cohost.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    checkHidenIn(item, type) {
        let valid = false;

        if (item && type && item.hiddenIn && item.hiddenIn.length && item.hiddenIn.includes(type)) {
            valid = true;
        }

        return valid;
    }

    checkGuest(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.guestSpeakers && item.guestSpeakers.length) {
            item.guestSpeakers.map((guest) => {
                if (guest && guest.id && guest.id === userUid && guest.status && guest.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    checkModerator(item, uid) {
        const { user } = this._store.getState().firebase;

        let userUid = uid ? uid : user && user.uid ? user.uid : '';
        let valid = false;

        if (userUid && item && item.moderators && item.moderators.length) {
            item.moderators.map((moderator) => {
                if (moderator && moderator.id && moderator.id === userUid && moderator.status && moderator.status === 'confirmed') {
                    valid = true;
                }
            })
            return valid;
        } else return valid;
    }

    readFromLiveTeasers() {
        // Create a reference with an initial file path and name
        var storage = firebase.storage();

        firebase.database().ref('teasers/list').get().then((snapshot) => {
            if (snapshot.exists()) {
                // console.log('-----------------------', snapshot.val());
                let list = snapshot.val();
                let teasers = {};
                if (list && Object.keys(list).length) {
                    Object.entries(list).forEach(async ([entry, eventId]) => {
                        teasers[eventId] = await storage.ref(`teasers/${entry}`).getDownloadURL().then((url) => {
                            // `url` is the download URL for 'images/stars.jpg'
                            return new Promise((resolve, reject) => {
                                // This can be downloaded directly:
                                var xhr = new XMLHttpRequest();
                                xhr.responseType = 'blob';
                                xhr.onreadystatechange = (evt) => {
                                    if (xhr.readyState === 4) {
                                        if (xhr.status === 200) {
                                            let reader = new window.FileReader();
                                            reader.readAsText(xhr.response);
                                            reader.onloadend = function () {
                                                resolve(reader.result);
                                            }
                                        } else {
                                            reject(new Error("Ajax error for " + url + ": " + xhr.status));
                                        }
                                    }
                                };
                                xhr.open('GET', url);
                                xhr.send();

                            })
                                ;
                        }).catch((error) => {
                            // Handle any errors
                        });;
                    });
                }
                this._store.dispatch(firebaseActions.setTeasers(teasers));
            } else {
                // console.log("No teasers available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case firebaseActions.FIREBASE_CREATE_CARD_ACTION: {
                    const data = action.payload;
                    this.createCard(data.stripeId, data.tokenId);
                    break;
                }
                case firebaseActions.FIREBASE_DELETE_CARD_ACTION: {
                    const data = action.payload;
                    this.deleteCard(data.stripeId, data.cardId);
                    break;
                }
                case firebaseActions.FIREBASE_CREATE_SUBSCRIPTION_ACTION: {
                    const data = action.payload;
                    this.createSubscription(data.stripeId, data.priceId);
                    break;
                }
                case firebaseActions.FIREBASE_CANCEL_SUBSCRIPTION_ACTION: {
                    const data = action.payload;
                    this.cancelSubscription(data.subscriptionId);
                    break;
                }
                case firebaseActions.FIREBASE_GET_VIDEOS_ACTION: {
                    const data = action.payload;
                    this.sortingVideos(data.videos);
                    break;
                }
                case firebaseActions.FIREBASE_SET_PROVIDER_ACTION: {
                    const data = action.payload;
                    this.logIn(data.provider);
                    break;
                }
                case firebaseActions.FIREBASE_LOG_OUT_ACTION: {
                    this.logOut();
                    break;
                }
                case firebaseActions.FIREBASE_CHECK_STRIPE_CUSTOMERS_ACTION: {
                    const data = action.payload;
                    // console.log('Check Stripe', data);
                    this.checkCustomers(data.user);
                    break;
                }
                case firebaseActions.FIREBASE_GET_CALENDAR_LIST_ACTION: {
                    const data = action.payload;
                    // console.log('Events', data);
                    this.sortCalendarEvents(data.calendarList);
                    break;
                }

                case paymentActions.PAYMENT_CREATE_INTENTS_ACTION: {
                    const data = action.payload;
                    // console.log('Create stripe intents', data);
                    this.createPaymentIntents(data);
                    break;
                }

                case firebaseActions.FIREBASE_SET_CHANNEL_LIST_ACTION: {
                    this.getMyStuffData();
                    break;
                }

                case firebaseActions.FIREBASE_GET_FEATURED_ACTION:
                case firebaseActions.FIREBASE_UPDATED_VIDEOS_ACTION: {
                    this.prepareFeaturedEvents();
                    break;
                }
            }
            return res;
        };
    }

    /**
     * Record and upload teaser and return data url
     * @returns {Promise<any[]>|*}
     */
    recAndUploadTeaser(eventId) {
        console.log('About to start recording teaser for event', eventId);
        return this.recordTeaser().then((dataUrl) => {
            console.log('About to start uploading teaser');
            let callableRef = firebase.functions().httpsCallable('uploadTeaser');
            return callableRef({ uri: dataUrl, eventId }).then((result) => {
                console.log('Successfully uploaded teaser', result.data);
                return result.data;
            }).catch((error) => {
                console.error('There was an error when calling the Cloud Function uploadTeaser', error);
            });
        }).catch((error) => {
            console.error('There was an error while recording and uploading video', error);
        });

    }

    /**
     * Record teaser and return data url
     * @returns {Promise<any[]>|*}
     */
    recordTeaser() {
        return this.getCurrentUserMediaStream().then((stream) => {
            console.log('Got user stream', stream);
            if (!stream)
                return Promise.resolve(null);
            let result = new Promise((resolve, reject) => {
                let chunks = [];
                let mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
                console.log('About to start recording');
                mediaRecorder.start();
                setTimeout(() => {
                    console.log('About to start recording');
                    // stop after 5 sec
                    mediaRecorder.stop();
                }, 5000);

                mediaRecorder.onstop = (e) => {
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    console.log("recorder stopped");
                    let blob = new Blob(chunks, { 'type': 'video/webm' });
                    var a = new FileReader();
                    a.onload = (e) => {
                        resolve(e.target.result);
                    }
                    a.readAsDataURL(blob);
                }

                mediaRecorder.ondataavailable = (e) => {
                    if (e.data.size > 0)
                        chunks.push(e.data);
                }
                mediaRecorder.onerror = (event) => {
                    let error = event.error;

                    switch (error.name) {
                        case InvalidStateError:
                            console.error("You can't record the video right now. Try again later.");
                            break;
                        case SecurityError:
                            console.error("Recording the specified source is not allowed due to security restrictions.");
                            break;
                        default:
                            console.error("A problem occurred while trying to record the video.");
                            break;
                    }
                    reject(e);
                };
            });
            return result;
        }).catch((err) => {
            console.log('Could not record the video: ' + err);
        });

    }

    /**
     * Get user media stream based on cookies
     * @returns {Promise<any[]>|*}
     */
    getCurrentUserMediaStream() {

        let videoEnabled = Cookies.get("videoEnabled");
        if (!videoEnabled || videoEnabled.toLowerCase() == 'false') {
            console.warn('Video not enabled');
            return Promise.resolve(null); // Video not enabled
        }

        let cameraCookies = Cookies.get("camera");

        if (typeof cameraCookies === 'string' || cameraCookies instanceof String) {
            try {
                cameraCookies = JSON.parse(cameraCookies);
            } catch (e) {
                console.warn('Parse Camera Cookies Error', e);
            }
        }

        if (!cameraCookies || (cameraCookies && !cameraCookies.deviceId)) {
            console.warn('Camera not selected');
            return Promise.resolve(null); // Camera not selected
        }
        let videoCookieExist = false;
        const constraints = {
            audio: false,
            video: {
                width: { max: 640, ideal: 480 },
                height: { max: 360, ideal: 270 }
            }
        }
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((source) => {
                if (cameraCookies && source && source.deviceId && cameraCookies.deviceId == source.deviceId) videoCookieExist = true;
            });
            if (
                devices.length == 0 ||
                (devices.length == 1 && devices[0].deviceId == "")
            ) {
                console.warn('Camera not found');
                return Promise.resolve(null); // Camera not found
            } else {
                if (!videoCookieExist) {
                    console.warn('Camera not found');
                    return Promise.resolve(null); // Camera not selected;
                }
                constraints.video = {
                    deviceId: { exact: cameraCookies.deviceId },
                };
                console.log('About to get video with consttraints', constraints)
                return navigator.mediaDevices.getUserMedia(constraints);
            }
        });
    }
}

export default new FirebaseClient();
