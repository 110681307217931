import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from './Button';

class Popover extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='popover-wrapper'>
                <div className='popover-main-wrapper'>
                    <div className='popover-main'>
                        <p className='popover-title'>Welcome to OVI</p>
                        <p className='popover-subtitle'>A creator-friendly streaming platform</p>
                        <Button
                            className='btn-ovi-popover'
                            onClick={() => this.props?.close()}
                        >
                            View Live Streams
                        </Button>
                    </div>
                </div>
                <div className='popover-bottom'>
                    <Link
                        to="#"
                        className='ovi-link'
                        onClick={() => this.props?.close()}
                    >
                        Live Now
                    </Link>
                </div>
            </div>
        )
    }
}

export default Popover;